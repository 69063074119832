
   import { Options, Vue } from 'vue-class-component';
   import Globals from '../Globals';
   import { API_BASE_URL } from '../environment'
   import { Menu, Account,User } from '../Types';
   import Recipe from '../models/Recipe';
   import Axios from 'axios';
   import BootstrapModal from '../components/BootstrapModal.vue';
   import BasicForm from '../components/BasicForm.vue'
   let newAccount:any = { name:'', type:'user', user: {name:'',email:'',password:''}};

   const unitsOptions = [{value:0,text:'Imperial'},{value:1,text:'Metric'}];
   const languagesOptions = [{value:1,text:'English'},{value:3,text:'Chinese'},{value:5,text:'Deutsh'},{value:7,text:'Turkish'},{value:8,text:'South Korean'}];

   @Options({components:{BootstrapModal,BasicForm},emits:['accountTypeFound']})
   export default class Dashboard extends Vue {

      recipes:Array<Recipe> = [];
      menus:Array<Menu> = [];
      accounts:Array<Account> = [];
      softwarePackages:Array<any> = [];
      rcPosts:Array<any> = [];
      RECIPE_ACCOUNTS:any = {};
      languages:Array<any> = [];
      serviceMenuRecipes:Array<any> = [];

      isSystemUser:boolean = false;

      showAccountModal:boolean = false;
      newAccount:any = newAccount;
      newAccountFields:Array<any> = [];
      showUserModal:boolean = false;
      userModalModel:User = new User;
      userFields:Array<any> = [];
      currentAccount:Account = new Account;
      currentAccountUsers:Array<User> = [];
      userToken:string = '';

      //computed
      get RECIPE_TYPES() { return Recipe.types; }

      //methods
      viewAccountUsers(account:Account) {
         this.currentAccount = account;
         Axios.get(`${API_BASE_URL}/users?account=${account.id}`,Globals.axiosConf)
         .then(({data})=>{
            this.currentAccountUsers = data;
         });
      }
      createUser() { this.editUser(new User); }
      editUser(user:User) {
         this.userModalModel = user;
         this.userFields = ['name','email','password','primaryLanguage','primaryUnits'].map((key:string)=>{
            return {
               key: key,
               placeholder:key,
               type:key =='email' ? 'email' : key == 'password' ? 'password' : ['primaryUnits','primaryLanguage'].indexOf(key) > -1 ? 'select' : 'text',
               label:key[0].toUpperCase()+key.slice(1).replace('_',' '),
               options:key == 'primaryUnits' ? unitsOptions : key == 'primaryLanguage' ? languagesOptions : null,
               //@ts-ignore
               model:user[key],
               //@ts-ignore
               update(val:string) { user[key] = val; },

            }
         })
         this.showUserModal = true;
      }
      resetUser() { this.showUserModal = false; }
      saveUser() {
         let userData = {...this.userModalModel,account:this.currentAccount.id};
         Axios[userData.id ? 'put' : 'post'](`${API_BASE_URL}/users${userData.id ? '/'+userData.id : ''}`,userData,Globals.axiosConf)
         .then(({data})=>{
            this.showUserModal = false;
            if(!userData.id) this.currentAccountUsers.push(new User(data));
            this.$forceUpdate();
         }).catch(e=>alert('user not saved'));
       }
      resetAccount() {  this.showAccountModal = false; }
      createAccount() {
         newAccount = { name:'', type:'user', user: {name:'',email:'',password:''}};
         this.newAccount = newAccount;
         this.newAccountFields = [
            {
               key: 'name',
               placeholder:'Account Name',
               type: 'text',
               label: 'Account Name',
               update(val:string) {  newAccount.name = val; }
            },
            {
               key: 'type',
               placeholder:'',
               type: 'select',
               label: 'Account type',
               options:[{value:'user',text:'User'},{value:'distro',text:'Distibutor'},{value:'system',text:'System'},{value:'import',text:"Import"}],
               update(val:string) { newAccount.type = val; }
            },
            {
               key: 'name',
               placeholder:'John Appleseed',
               type: 'text',
               label: 'First User\'s Name',
               model:this.newAccount.user.name,
               update(val:string) { newAccount.user.name = val; }
            },
            {
               key: 'email',
               placeholder:'john@appleseed.com',
               type: 'email',
               label: 'First User\'s Email',
               model:this.newAccount.user.email,
               update(val:string) { newAccount.user.email = val; }
            },
            {
               key: 'password',
               placeholder:'top secret password',
               type: 'password',
               label: 'First User\'s Password',
               model: this.newAccount.user.password,
               update(val:string) { newAccount.user.password = val; }
            }
         ];
         this.showAccountModal = true;
      }
      editAccount(account:Account) {
         newAccount = account;
         this.newAccount = newAccount;
         this.newAccount.user = {email:false};
         this.newAccountFields = ['name','type'].map((ky:string)=>{
               return {
                  key: ky,
                  placeholder:ky,
                  type: 'text',
                  label: ky[0].toUpperCase() + ky.slice(1),
                  update(val:string) {  newAccount[ky] = val; },
                  model:newAccount[ky]
               }
            });
            this.showAccountModal = true;
      }
      saveAccount() {
         let accountData:any = this.newAccount.id ? {...this.newAccount} : {...this.newAccount,control_account: Globals.me.account,import:0,sharing:1};
         delete accountData.user;

         Axios[accountData.id ? 'put' : 'post'](`${API_BASE_URL}/accounts${accountData.id ? '/'+accountData.id : ''}`,accountData,Globals.axiosConf)
         .then(({data})=>{
            if(this.newAccount.user.email) {
               let userData = {...this.newAccount.user,account:data.id,can_get_menu: 1,can_manage_recipe: 1,can_make_menu: 1,can_approve_recipe:1,can_admin:1};
               Axios.post(`${API_BASE_URL}/users`,userData,Globals.axiosConf)
               .then(({data})=>{
                  //@ts-ignore
                  window.jQuery('#accountModal').modal('hide')
                  this.accounts.push(new Account(data));
                  this.$forceUpdate();
               }).catch(e=>alert('new account user not saved'));
            } else {
               //@ts-ignore
               window.jQuery('#accountModal').modal('hide');
               this.$forceUpdate();
            }
         }).catch(e=>alert('new account not saved'));
      }


      //lifecycle
      async created() {
         if(!Globals.isAuthenticated().token) return this.$router.push({name:'Login'})
         await Globals.isMeReady().catch(e=>{ alert('Can\'t find you, please logout and try again')});

         this.RECIPE_ACCOUNTS[Globals.me.account] = Globals.me.account_name;
         this.isSystemUser = Globals.me.rootAccount.type == 'system';
         this.userToken = Globals.me.auth_key;

         //@ts-ignore
         this.$root.updateSystemUser(Globals.me.rootAccount.type);

         //check system functions scroll
         if(window.location.href.indexOf('#system_functions') > -1) setTimeout(()=>{ window.scrollTo(0,1500)}, 500);

         //get recipes
         Axios.get(`${API_BASE_URL}/recipes?account=${Globals.currentAccountId}&columns=id,name,type`,Globals.axiosConf)
         .then(({data})=>{
            this.recipes = data.filter((rec:any)=>rec.type > 0).map((rec:any)=>new Recipe(rec)).slice(0,5);
         }).catch(e=>{ console.error(e); });

         //get menus
         Axios.get(`${API_BASE_URL}/compiles?account=${Globals.currentAccountId}&columns=id,description,account`,Globals.axiosConf)
         .then(({data})=>{
            this.menus = data;
         }).catch(e=>{ console.error(e);});

         //get Accounts
         Axios.get(`${API_BASE_URL}/accounts?control_account=${Globals.currentAccountId}&columns=id,name,control_account,type`,Globals.axiosConf)
         .then(({data})=>{
            this.accounts = data.map((acct:any)=>new Account(acct));
         }).catch(e=>{ console.error(e);});
         Axios.get(`${API_BASE_URL}/accounts/${Globals.currentAccountId}`,Globals.axiosConf)
         .then(({data})=>{
            this.accounts.push(new Account(data));
         }).catch(e=>{ console.error(e);});

         //get softwarePackges
         Axios.get(`https://craftcloud-softwares.azurewebsites.net/software-packages`,{headers:{Authorization:"7a7c8582-9e9f-4f32-bb46-28a1dd3ed341"}})
         .then(({data})=>{
            this.softwarePackages = data;
         }).catch(e=>{ console.error(e);});

         //get resource center post
         Axios.get(`https://rc-cs.franke.com/site/post-by-tag/3?multi&tag&json`)
         .then(({data})=>{
            this.rcPosts = data.sort(() => Math.random() - 0.5).slice(0,15);
         }).catch(e=>{ console.error(e);});

         //get lanugages
         Axios.get(`${API_BASE_URL}/languages`,Globals.axiosConf)
         .then(({data})=>{
            this.languages = data;
         }).catch(e=>console.error('no languages'));

         //get service menu
         Axios.get(`${API_BASE_URL}/service-menu-files?columns=id,name`,Globals.axiosConf)
         .then(({data})=>{
            this.serviceMenuRecipes = data;
         }).catch(e=>console.error('no service menus'));
      }
   }
